import React, { FC } from 'react'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import { NABVARMOBILECONFIG, NAVBARICONCONFIG } from '@/utils/navigation/navigationData'
import { NavigationContextTV } from '@/utils/navigation/TV'
import WrappedNavigationListItem from '@/components/WrappedNavigationListItem'

type Props = {
    toggleHidden: () => void
    hidden: boolean
}

const MobileNavigation: FC<Props> = ({ toggleHidden, hidden }) => {
    const navigationItems = React.useContext(NavigationContextTV)
    return (
        <div
            className={`absolute top-0 left-0 right-0 bottom-0 z-50 h-screen w-screen bg-white text-black ${
                hidden ? 'hidden' : ''
            }`}
        >
            <div className="flex w-full justify-end">
                <button onClick={toggleHidden} className="p-4">
                    <FontelloIcon name={NABVARMOBILECONFIG.CLOSE.IMAGE} color="#000" />
                </button>
            </div>
            <WrappedNavigationListItem
                href=""
                title={NABVARMOBILECONFIG.LIVE.TITLE}
                leadingType="icon"
                leading="live"
                iconSize="14px"
                trailingIcon="scroll-right"
                trailingIconColor="onyx"
                onPress={() => window.location.assign(NAVBARICONCONFIG.LIVE.LINK)}
            />
            <WrappedNavigationListItem
                href=""
                title={NABVARMOBILECONFIG.VIDEOS.TITLE}
                leadingType="icon"
                leading="media"
                iconSize="18px"
                trailingIcon="scroll-right"
                trailingIconColor="onyx"
                onPress={() => window.location.assign(NAVBARICONCONFIG.VIDEOS.LINK)}
            />
            <WrappedNavigationListItem
                href=""
                title={NABVARMOBILECONFIG.TV.TITLE}
                leadingType="icon"
                tintColor="black"
                backgroundColor="moon-3"
                leading="tv"
                iconSize="15px"
                trailingIcon="scroll-bottom"
                trailingIconColor="onyx"
                onPress={() => window.location.assign(NAVBARICONCONFIG.TV.LINK)}
            />

            <ul className="list-reset flex-1 items-center justify-end lg:flex">
                {navigationItems.map((navItem, index) => {
                    const isExternal = navItem?.urlType === 'EXTERNAL'

                    return (
                        <li key={`${navItem.caption}-${index}`} className="pl-5">
                            <WrappedNavigationListItem
                                href=""
                                title={navItem.caption}
                                trailingIcon="scroll-right"
                                trailingIconColor="onyx"
                                onPress={() =>
                                    !isExternal
                                        ? window.location.assign(navItem.meta.path || '')
                                        : window.location.replace(navItem.url || '')
                                }
                            />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default MobileNavigation
